<template>
  <div class="analyze">
    <today-info :data="data" :type="type"></today-info>
    <history :data="data" :type="type"></history>
  </div>
</template>
<script>
import TodayInfo from "./components/TodayInfo";
import History from "./components/History";

export default {
  name: "Analyze",
  components: {
    TodayInfo,
    History
  },
  data() {
    return {
      data: {},
      type: 3
    };
  },
  mounted() {
    this.getCountData_BManage();
    const { Type } = this.$route.query;
    if(Type)
    this.type = Type;
  },
  methods: {
    // 首次加载数据
    getCountData_BManage() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {};
      this.$api.countData
        .countData_BManage(param)
        .then(res => {
          //console.log(res.data);
          const data = res.data;
          //console.log(data);
          this.data = data;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch(error => {
          this.isfirst = false;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status
          });
        });
      //console.log("这是isfirst" + this.isfirst);
    }
  }
};
</script>
<style lang="scss" scoped>
.analyze {
  width: 100%;
  // padding-bottom: 10%;
  // background-color: red;
}
</style>
